import React, { Component } from 'react';
import ReactTable from 'react-table';
import Button from '../../../../components/Button';
import InvoiceButton from '../../../../assets/images/invoice-button.png';
import Highcharts from 'highcharts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  rupeeDecimalFormatter,
  integerToWordFormatterLabels,
} from '../../../../utils/utility';
import { getLatestPayments, getBuyerSummary } from './home.action';
import CredoModal from '../../../../components/CredoModal';
import PaymentInvoiceModal from '../Invoices/PaymentModal';
import TotalLimit from '../../../../assets/images/total-limit.svg';
import UtilizedLimit from '../../../../assets/images/utilized-limit.svg';
import AvailableLimit from '../../../../assets/images/available-limit.svg';
import Greentick from '../../../../assets/images/green tick.svg';
import Processing from '../../../../assets/images/Processing.svg';
import File from '../../../../assets/images/form.svg';
import TextLabel from '../../../../components/TextLabel';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDatePicker: false,
      pageNumber: 1,
      searchBy: 'latestTransactionDate',
      searchValue: '',
      from: '',
      to: '',
    };
  }

  componentDidMount = async () => {
    let { match, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue, from, to } = this.state;
    const { getLatestPayments, getBuyerSummary } = actions;
    await getLatestPayments(
      from,
      pageNumber,
      searchBy,
      searchValue,
      to,
      anchorId
    );
    await getBuyerSummary(anchorId);
    let { supplierSummaryData } = this.props;
    Highcharts.chart('bar-graph-container', {
      chart: {
        type: 'column',
        margin: [50, 30, 50, 70],
        spacingLeft: 70,
      },
      credits: null,
      title: { text: '' },
      xAxis: {
        categories: ['Total', 'Paid', 'Outstanding', 'Overdue'],
        gridLineWidth: 0,
      },
      yAxis: {
        title: {
          text: '',
        },
        gridLineWidth: 0,
        lineWidth: 1,
        labels: {
          formatter: function () {
            return this.value;
          },
          align: 'right',
        },
        tickAmount: 5,
      },
      plotOptions: {
        column: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return integerToWordFormatterLabels(this.y);
            },
            crop: false,
            overflow: 'allow',
            y: -5,
          },
        },
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.x}: ${rupeeDecimalFormatter(this.y)}</b>`;
        },
      },
      series: [
        {
          data: [
            {
              y: supplierSummaryData?.totalInvoicesAmount,
              color: 'rgba(59,130,246,0.8)',
            },
            {
              y: supplierSummaryData?.paidInvoicesAmount,
              color: 'rgba(24, 136, 101,0.8)',
            },
            {
              y: supplierSummaryData?.outstandingInvoicesAmount,
              color: 'rgba(249, 118, 118,0.8)',
            },
            {
              y: supplierSummaryData?.overdueInvoicesAmount,
              color: 'rgba(255, 156, 65,0.8)',
            },
          ],
          showInLegend: false,
        },
      ],
    });
  };

  getPageData = async (type, totalPages) => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue, from, to } = this.state;
    const { getLatestPayments } = actions;
    let updatedPageNumber = pageNumber;
    if (type === 'next' && pageNumber < totalPages) {
      updatedPageNumber = pageNumber + 1;
    } else if (type === 'prev' && pageNumber > 1) {
      updatedPageNumber = pageNumber - 1;
    }
    await this.setState({ pageNumber: updatedPageNumber });
    getLatestPayments(
      from,
      updatedPageNumber,
      searchBy,
      searchValue,
      to,
      anchorId
    );
  };

  onResetFilter = () => {
    let { match, actions } = this.props,
      { anchorId } = match.params;
    const { getLatestPayments } = actions;
    let { searchBy } = this.state;
    this.setState(
      {
        pageNumber: 1,
        searchBy: 'latestTransactionDate',
        searchValue: '',
        from: '',
        to: '',
      },
      () => getLatestPayments('', 1, 'latestTransactionDate', '', '', anchorId) //SearchBy and searchValue should be '' and pageNumber is 1 .
    );
  };

  onDateFilter = async (e) => {
    e.preventDefault();
    let { match, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchValue, from, to } = this.state;
    const { getLatestPayments } = actions;
    const formattedStartDate = from
      ? from?.split('-')?.reverse()?.join('/')
      : '';
    const formattedEndDate = to ? to?.split('-')?.reverse()?.join('/') : '';

    await this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
      from: formattedStartDate,
      to: formattedEndDate,
      pageNumber: 1,
      searchBy: '',
    }));
    let { searchBy } = this.state;
    getLatestPayments(
      formattedStartDate,
      pageNumber,
      searchBy,
      searchValue,
      formattedEndDate,
      anchorId
    );
  };

  toggleModal = (modalType, original) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      paymentModal: original,
    });
  };

  getDate = () => {
    const { from, to } = this.state;
    const formattedStartDate = from ? from : '';
    const formattedEndDate = to ? to : '';
    if (formattedStartDate === formattedEndDate) {
      return formattedStartDate;
    } else {
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
  };

  render() {
    let { match } = this.props,
      { anchorId } = match.params;
    let { showDatePicker, isModalOpen, pageNumber, paymentModal, searchBy } =
      this.state;
    let { supplierSummaryData, latestPaymentsData } = this.props;
    let { totalPage } = latestPaymentsData || {};
    const paymentInvoicesColumn = [
      {
        Header: () => (
          <>
            <div className='ml-4'>Party Name</div>
          </>
        ),
        accessor: 'partyName',
        width: 280,
        Cell: (props) => {
          let { original } = props;
          let { partyName } = original;
          return <>{<div>{partyName || 'NA'}</div>}</>;
        },
      },

      {
        Header: () => (
          <>
            <div className='ml-2'>Invoice Number</div>
          </>
        ),
        accessor: 'listOfInvNumber',
        Cell: ({ original }) => {
          return (
            <>
              <img className='mr-2 img-icon' src={File} />
              <span
                className='invoice_number mr-5'
                onClick={() => {
                  this.toggleModal(PaymentInvoiceModal, original);
                }}
              >
                {original?.listOfInvNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: 'Payment Type',
        accessor: 'paymentType',
        width: 130,
      },

      {
        Header: 'Payment Amount',
        accessor: 'paymentAmount',
        Cell: (props) => {
          let { original } = props;
          let { paymentAmount } = original;
          return <>{rupeeDecimalFormatter(paymentAmount, '₹')}</>;
        },
      },
      {
        Header: 'Payment Date',
        accessor: 'paymentDate',
        Cell: (props) => {
          let { original } = props;
          let { paymentDate } = original;
          return <div className='ml-3'>{paymentDate}</div>;
        },
      },
      {
        Header: 'UTR',
        accessor: 'utrNumber',
        Cell: (props) => {
          let { original } = props;
          let { utrNumber } = original;
          return (
            <>
              <div className='ml-3'>{utrNumber}</div>
              <div>
                {original?.status === 'processed' ? (
                  <div className='d-flex ml-3'>
                    <img className='img-icon' src={Greentick} />
                    <div className='ml-3'>Processed</div>
                  </div>
                ) : original?.status === 'disbursal_pending_with_lendar' ? (
                  <div className='d-flex ml-3'>
                    <img className='img-icon' src={Processing} />
                    <div className='ml-3'>Processing</div>
                  </div>
                ) : null}
              </div>
            </>
          );
        },
        width: 230,
      },
    ];

    const invoicesColumn = [
      {
        Header: '',
        accessor: 'parties',
      },
      {
        Header: 'Party',
        accessor: 'noofparties',
      },
      {
        Header: 'Invoices',
        accessor: 'invoices',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: (props) => {
          let { original } = props;
          let { amount } = original;
          return <>{rupeeDecimalFormatter(amount, '₹')}</>;
        },
      },
    ];
    const data = [
      {
        parties: `Total`,
        noofparties: `${supplierSummaryData?.totalParties}`,
        invoices: `${supplierSummaryData?.totalInvoices}`,
        amount: `${supplierSummaryData?.totalInvoicesAmount}`,
      },
      {
        parties: `Paid`,
        noofparties: `${supplierSummaryData?.paidParties}`,
        invoices: `${supplierSummaryData?.paidInvoices}`,
        amount: `${supplierSummaryData?.paidInvoicesAmount}`,
      },
      {
        parties: `Outstanding`,
        noofparties: `${supplierSummaryData?.outstandingParties}`,
        invoices: `${supplierSummaryData?.outstandingInvoices}`,
        amount: `${supplierSummaryData?.outstandingInvoicesAmount}`,
      },
      {
        parties: `Overdue`,
        noofparties: `${supplierSummaryData?.overdueParties}`,
        invoices: `${supplierSummaryData?.overdueInvoices}`,
        amount: `${supplierSummaryData?.overdueInvoicesAmount}`,
      },
    ];

    return (
      <>
        <div className='container invoices-wrapper  interest_charges_wrapper mt-5'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card m-r-2'>
                <h2 className='card_heading d-flex justify-content-between'>
                  Limit Usage
                </h2>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <img src={TotalLimit} />
                      <TextLabel
                        label='Total Limit'
                        value={rupeeDecimalFormatter(
                          supplierSummaryData?.totalLimit,
                          '₹'
                        )}
                        className='col-md-3 pt-2'
                      />
                      <img src={UtilizedLimit} />
                      <TextLabel
                        label='Total Utilized Limit'
                        value={rupeeDecimalFormatter(
                          supplierSummaryData?.utilizedLimit,
                          '₹'
                        )}
                        className='col-md-3 pt-2'
                      />
                      <img src={AvailableLimit} />
                      <TextLabel
                        label='Available Limit'
                        value={rupeeDecimalFormatter(
                          supplierSummaryData?.availableLimit,
                          '₹'
                        )}
                        className='col-md-3 pt-2'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='card'>
                <h3 className='col-md-11 card_heading'>Summary</h3>
                <div
                  id='bar-graph-container'
                  style={{ width: '100%', height: '330px', marginLeft: '20px' }}
                ></div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='card'>
                <h3 className=' card_heading mt--2'>Invoices Details</h3>
                <div className='mt-2'>
                  <ReactTable
                    data={data}
                    columns={invoicesColumn}
                    defaultPageSize={5}
                    showPagination={false}
                    sortable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='row d-flex align-items-center justify-content-between'>
              <h3 className='col-md-11 card_heading'>
                Collections on {this.getDate()}
                {this.state.from === '' && searchBy === 'latestTransactionDate'
                  ? latestPaymentsData?.listOfPaymentHistory?.[0]?.paymentDate
                  : null}
              </h3>
              <div
                className='col-md-1 outline_button'
                onClick={() => {
                  let { history, match } = this.props,
                    { anchorId } = match.params;
                  history.push(`/anchor/${anchorId}/supplier/invoices`);
                }}
              >
                View All
              </div>
            </div>
            <div className='row align-items-center'>
              <TextLabel
                label='Payment Received'
                value={rupeeDecimalFormatter(
                  latestPaymentsData?.paymentRecieved,
                  '₹'
                )}
                className='col-md-9'
              />
              <div className='col-md-3 card_heading_secondary_right'>
                <Button className='mr-3' onClick={this.onResetFilter}>
                  Reset
                </Button>
                <img
                  src={InvoiceButton}
                  alt='icon'
                  className='icon_image'
                  onClick={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      showDatePicker: !prevState.showDatePicker,
                    }))
                  }
                />
              </div>
            </div>
            {showDatePicker ? (
              <div className='row d-flex justify-content-end'>
                <div className='card date-picker col-4'>
                  <div className='row  '>
                    <div className='col text-grey mt-4'>
                      Start Date
                      <input
                        className='date'
                        type='date'
                        value={this.state.from.split('/').reverse().join('-')}
                        onChange={(e) =>
                          this.setState({
                            from: e.target?.value
                              ?.split('-')
                              .reverse()
                              .join('/'),
                          })
                        }
                      ></input>
                    </div>
                    <div className='col text-grey mt-4'>
                      End Date
                      <input
                        className='date'
                        type='date'
                        value={this.state.to.split('/').reverse().join('-')}
                        onChange={(e) =>
                          this.setState({
                            to: e.target?.value?.split('-').reverse().join('/'),
                          })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className='row mt-3 ml-2'>
                    <Button onClick={(e) => this.onDateFilter(e)}>OK</Button>
                  </div>
                </div>
              </div>
            ) : null}
            <div className='mt-4'>
              <ReactTable
                data={latestPaymentsData?.listOfPaymentHistory}
                columns={paymentInvoicesColumn}
                defaultPageSize={7}
                showPagination={false}
                sortable={false}
              />
              <ul className={'datatable-pagination'}>
                <li>
                  <button
                    disabled={!(pageNumber > 1)}
                    className={'prev-btn'}
                    type={'button'}
                    onClick={() => this.getPageData('prev', totalPage)}
                  >
                    Previous
                  </button>
                </li>
                <li>
                  <span>
                    Page No. {totalPage === 0 ? 0 : pageNumber} of {totalPage}
                  </span>
                </li>
                <li>
                  <button
                    disabled={!(totalPage > pageNumber)}
                    className={'next-btn'}
                    type={'button'}
                    onClick={() => this.getPageData('next', totalPage)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <CredoModal
            isOpen={isModalOpen}
            styles={{
              content: {
                width: '80%',
                height: '80%',
              },
            }}
            closeAction={() => this.toggleModal('')}
            RenderingComponent={PaymentInvoiceModal}
            toggleModal={this.toggleModal}
            paymentModal={paymentModal}
            supplierId={anchorId}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ homeReducer }) => ({
  latestPaymentsData: homeReducer?.latestPaymentsData,
  supplierSummaryData: homeReducer?.supplierSummaryData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getLatestPayments,
      getBuyerSummary,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { Component } from 'react';
import AddProgram from '../AnchorModal/AddProgram';
import CredoModal from '../../../../components/CredoModal';
import AddSubProgram from '../AnchorModal/AddSubProgram';
import ViewSubProgram from '../AnchorModal/ViewSubProgram';
import { API_FETCH_PROGRAM } from '../../../../utils/APIUrls';
import { GET } from '../../../../utils/webAPI.service';
import { rupeeDecimalFormatter } from '../../../../utils/utility';
import NoDataFound from '../../../../components/NoDataFound';
import TextLabel from '../../../../components/TextLabel';
export default class Downstream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      downstream: {},
      subProgramDetails: {},
    };
  }

  toggleModal = (modalType, subProgramDetails) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      subProgramDetails,
    });
  };

  anchorView = (anchorId) => {
    var win = window.open(`/anchor/${anchorId}/supplier/home`);
    win?.focus();
  };

  fetchDetails = () => {
    let {
      match: {
        params: { appId },
      },
    } = this.props || {};
    GET(API_FETCH_PROGRAM(appId))
      .then(async ({ data }) => {
        if (data?.code === 200) {
          await this.setState({
            downstream: data?.data,
          });
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  componentDidMount = () => {
    this.fetchDetails();
  };

  render() {
    let { isModalOpen, modalType, downstream, subProgramDetails } = this.state;
    let { programs } = downstream || {};
    let filterDownstream = programs?.find(
      (ele) => ele?.programType === 'supplier'
    );
    let { subPrograms = [] } = filterDownstream || {};
    return (
      <>
        <div className='container'>
          <div className='d-flex modal-wrapper justify-content-end mt-2'>
            <div className='save_button mb-1'>
              <button
                className='save_button button-success px-4 py-2'
                onClick={() => {
                  this.toggleModal(AddProgram);
                }}
              >
                Add New Program
              </button>
            </div>
          </div>
          <div className='invoices-wrapper'>
            <div className='card px-5'>
              <div className='m-2'>
                <h1 className='mt-5'> {filterDownstream?.anchorName}</h1>
                <div className='text-grey m-0'>
                  {filterDownstream?.gstin || '-'}
                </div>
              </div>
              <div className='card'>
                <div className='row border-bottom'>
                  <div className='col-10'>
                    <h2 className='card_heading mb-0'>Program Details</h2>
                  </div>
                  <div className='col-2 modal-wrapper'>
                    <div className='save_button pb-3'>
                      <button
                        className='save_button button-success px-4 py-2'
                        type='submit'
                        onClick={() =>
                          this.anchorView(filterDownstream?.supplierId)
                        }
                      >
                        Anchor view
                      </button>
                    </div>
                  </div>
                </div>
                <div className='row application-wrapper'>
                  {filterDownstream ? (
                    <div className='col-md-12'>
                      <div className='row '>
                        <TextLabel
                          label='Program Id'
                          value={filterDownstream?.programId ?? '-'}
                          className='col-md-4'
                        />
                        <TextLabel
                          label='Program Name'
                          value={filterDownstream?.programName ?? '-'}
                          className='col-md-4'
                        />
                        <TextLabel
                          label='Program Type'
                          value={
                            filterDownstream?.programType?.toUpperCase() ?? '-'
                          }
                          className='col-md-4'
                        />
                        <TextLabel
                          label='Master Limit Amount'
                          value={rupeeDecimalFormatter(
                            filterDownstream?.limitAmount,
                            '₹'
                          )}
                          className='col-md-4'
                        />
                        <TextLabel
                          label='Limit Utilized'
                          value={rupeeDecimalFormatter(
                            filterDownstream?.limitUtilized,
                            '₹'
                          )}
                          className='col-md-4'
                        />
                        <TextLabel
                          label='Balance Limit'
                          value={rupeeDecimalFormatter(
                            filterDownstream?.balanceLimit,
                            '₹'
                          )}
                          className='col-md-4'
                        />
                        <TextLabel
                          label='Program Start'
                          value={filterDownstream?.startDate ?? '-'}
                          className='col-md-4'
                        />
                        <TextLabel
                          label='Program End'
                          value={filterDownstream?.endDate ?? '-'}
                          className='col-md-8'
                        />
                        <TextLabel
                          label='Lender'
                          value={filterDownstream?.lenderName ?? '-'}
                          className='col-md-4'
                        />
                        <TextLabel
                          label='Status'
                          value={
                            filterDownstream?.status === 'Active' ? (
                              <span className='capsule mt-2  capsule_new_green'>
                                {filterDownstream?.status}
                              </span>
                            ) : filterDownstream?.status === 'inActive' ? (
                              <span className='capsule mt-2  capsule_red'>
                                {filterDownstream?.status}
                              </span>
                            ) : null
                          }
                          className='col-md-4'
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='col-12 '>
                      <NoDataFound
                        primaryMessage={`No Program Available`}
                        secondaryMessage={`Please Add New Program`}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='card mt-5 px-5'>
                <div className='invoice-header d-flex border-bottom'>
                  <h3 className='card_heading'>Sub-Programs</h3>
                  <div className='modal-wrapper'>
                    <div className='save_button py-3'>
                      <button
                        className='save_button button-success px-4 py-2'
                        onClick={() => {
                          this.toggleModal(AddSubProgram);
                        }}
                      >
                        Add new Sub Program
                      </button>
                    </div>
                  </div>
                </div>
                {subPrograms?.length > 0 ? (
                  <>
                    {' '}
                    {subPrograms?.map((element, i) => {
                      return (
                        <>
                          <div className='card mt-3'>
                            <div className='row mt-3 d-flex justify-content-end'>
                              <div className='col-10 mt-4'>
                                <div className='text-dark h3'>
                                  {element?.subProgramName || '-'}
                                </div>
                                <div className='text-grey m-0'>
                                  {element?.subProgramId || '-'}
                                </div>
                              </div>
                              <div className='col-2 modal-wrapper'>
                                <div className='save_button'>
                                  <button
                                    className='save_button button-success px-5 py-2'
                                    onClick={() => {
                                      this.toggleModal(ViewSubProgram, element);
                                    }}
                                  >
                                    View
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <div className='col-12 '>
                    <NoDataFound primaryMessage={`No Sub-Program Available`} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <CredoModal
            isOpen={isModalOpen}
            {...this.props}
            styles={
              modalType === AddProgram
                ? {
                    content: {
                      width: '50%',
                      height: '60%',
                    },
                  }
                : {
                    content: {
                      width: '70%',
                      height: 'max-content',
                    },
                  }
            }
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            toggleModal={this.toggleModal}
            anchorData={filterDownstream}
            subProgramDetails={subProgramDetails}
            fetchDetails={this.fetchDetails}
          />
        </div>
      </>
    );
  }
}

import React, { Component } from 'react';
import InvoiceButton from '../../../assets/images/invoice-button.png';
import SearchIcon from '../../../assets/images/SearchIcon.svg';
import ReactTable from 'react-table';
import Button from '../../../components/Button/index';
import CredoModal from '../../../components/CredoModal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  API_GET_LOAN_INFO,
  API_POST_INVOICE_DETAILS,
} from '../../../utils/APIUrls';
import { getDraftedList, getDraftedSummary } from './draftedInvoices.action';
import { GET, POST } from '../../../utils/webAPI.service';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { loaderModal } from '../../../store/actions';
import DraftedInvoiceModal from './draftedModal';
import {
  STATUS_PARAMS,
  STATUS_PARAMS_VALUE_MAP,
} from '../ALLInvoices/AllInvoices.config';
import TextLabel from '../../../components/TextLabel';
class DraftedInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      showDatePicker: false,
      isModalOpen: false,
      modalType: '',
      invoicesTableData: [],
      invoiceModalDetails: {},
      currentPage: 1,
      endDate: null,
      startDate: null,
      duration: null,
    };
  }

  getPageData = (type, totalPages) => {
    let { currentPage } = this.state;
    if (type === 'next' && currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    } else if (type === 'prev' && currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value?.trim(),
    });
  };

  searchHandler = async (e) => {
    e.preventDefault();
    let { actions } = this.props;
    let { searchBy, searchValue, duration } = this.state;
    let { getDraftedList, loaderModal } = actions;
    await this.setState({
      currentPage: 1,
    });
    let dataToSend = {
      duration,
      endDate: null,
      numberOfRows: 10,
      pageNumber: 1,
      searchParam: searchBy,
      searchValue: searchValue,
      startDate: null,
      type: 'drafted',
    };
    if (searchBy === undefined) {
      alert('Please select search type');
      return;
    }
    loaderModal(true);
    await getDraftedList(dataToSend);
    loaderModal(false);
  };

  toggleModal = (
    modalType,
    invoiceId,
    userId,
    invoiceAmount,
    fundedAmount,
    subventionAmount,
    marginAmount
  ) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      paymentModalData: {
        invoiceId,
        invoiceAmount,
        fundedAmount,
        subventionAmount,
        marginAmount,
      },
    });
    let dataToSend = {
      invoiceId,
      userId,
    };
    if (invoiceId) {
      POST(API_POST_INVOICE_DETAILS, dataToSend)
        .then(({ data }) => {
          if (data.code === 200) {
            this.setState({
              invoiceModalDetails: data,
            });
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  getFilterData = async (duration) => {
    await this.setState((prevState) => ({
      ...prevState,
      showFilter: !prevState.showFilter,
      duration,
      currentPage: 1,
    }));
    this.invoiceData();
  };

  getDateFilterData = async () => {
    let { actions } = this.props;
    let { endDate, startDate } = this.state;
    let { getDraftedList, getDraftedSummary } = actions;
    let dataToSend = {
      endDate: endDate?.split('-')?.reverse()?.join('-'),
      numberOfRows: 10,
      pageNumber: 1,
      searchParam: null,
      searchValue: null,
      startDate: startDate?.split('-')?.reverse()?.join('-'),
      type: 'drafted',
    };
    loaderModal(true);
    await getDraftedList(dataToSend);
    await getDraftedSummary(dataToSend);
    loaderModal(false);
    this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
      showFilter: !prevState.showFilter,
      currentPage: 1,
    }));
  };

  getPageChangeInvoiceData = async () => {
    let { actions } = this.props;
    let { duration, currentPage, searchBy, searchValue, endDate, startDate } =
      this.state;
    let { getDraftedList, loaderModal } = actions;
    let dataToSend = {
      duration,
      endDate: endDate?.split('-')?.reverse()?.join('-'),
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: startDate?.split('-')?.reverse()?.join('-'),
      type: 'drafted',
    };
    loaderModal(true);
    await getDraftedList(dataToSend);
    loaderModal(false);
  };

  getLoanInfo = (userId) => {
    loaderModal(true);
    GET(API_GET_LOAN_INFO(userId))
      .then(({ data }) => {
        if (data?.code === 200) {
          if (data?.data) {
            setTimeout(function () {
              let newWindow = window.open(
                `/pay-app/${data?.data?.loanId}/${userId}/application/overview`
              );
              newWindow.focus();
            }, 350);
            loaderModal(false);
          }
        } else {
          alert(data?.message);
          loaderModal(false);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
    loaderModal(false);
  };

  invoiceData = async () => {
    let { actions } = this.props;
    let { duration, currentPage, searchBy, searchValue } = this.state;
    let { getDraftedList, getDraftedSummary, loaderModal } = actions;
    let dataToSend = {
      duration: duration || 'till_now',
      endDate: null,
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: null,
      type: 'drafted',
    };
    loaderModal(true);
    await getDraftedList(dataToSend);
    await getDraftedSummary(dataToSend);
    loaderModal(false);
  };

  componentDidMount() {
    let { currentPage } = this.state;
    this.invoiceData(currentPage);
  }

  render() {
    let {
      showFilter,
      showDatePicker,
      modalType,
      invoiceModalDetails,
      currentPage,
      paymentModalData,
    } = this.state;
    let { draftedList, draftedSummary } = this.props;
    let { numberOfPages } = draftedList || {};
    let { invoiceDetails } = draftedList || [];
    let { summary } = draftedSummary || {};
    let { drafted } = summary || {};
    const draftedInvoicesColumn = [
      {
        Header: 'User Id',
        accessor: 'userId',
        Cell: (props) => {
          let { original } = props;
          let { userId } = original;
          return (
            <>
              {
                <div
                  className='invoice-pointer'
                  onClick={() => this.getLoanInfo(userId)}
                >
                  {userId || 'NA'}
                </div>
              }
            </>
          );
        },
      },
      {
        Header: 'Mobile No',
        accessor: 'mobileNumber',
      },
      {
        Header: 'Invoice Id',
        accessor: 'invoiceId',
        Cell: (props) => {
          let { original } = props;
          let { invoiceId, disbursalStatus } = original;
          return (
            <>
              <div className=''>{invoiceId}</div>
              {disbursalStatus === STATUS_PARAMS.DRAFT ? (
                <span className='capsule  capsule_blue'>
                  {STATUS_PARAMS_VALUE_MAP.DRAFT}
                </span>
              ) : (
                <span className='capsule  capsule_blue'>{disbursalStatus}</span>
              )}
            </>
          );
        },
      },
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
      },

      {
        Header: 'Invoice Amount',
        accessor: 'invoiceAmount',
        Cell: (props) => {
          let { original } = props;
          let { invoiceAmount } = original;

          return (
            <>
              <div className=''>
                {rupeeDecimalFormatter(invoiceAmount, '₹')}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: (props) => {
          let { original } = props;
          let { invoiceId, userId } = original;

          return (
            <button
              className='details_button '
              onClick={() =>
                this.toggleModal(DraftedInvoiceModal, invoiceId, userId)
              }
            >
              Details
            </button>
          );
        },
      },
    ];

    return (
      <>
        <div className='container invoices-wrapper'>
          <div className='card'>
            <div className='invoice-header'>
              <h3 className='card_heading'>INVOICE SUMMARY</h3>
              <img
                onClick={() =>
                  this.setState((prevState) => ({
                    ...prevState,
                    showFilter: !prevState.showFilter,
                  }))
                }
                alt='filter'
                className='invoice-btn mt-5'
                src={InvoiceButton}
              />
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <div className='row'>
                  <TextLabel
                    label='Drafted Invoice'
                    value={drafted?.count}
                    className='col-md-12'
                  />
                  <TextLabel
                    label='Total Amount'
                    value={rupeeDecimalFormatter(drafted?.amount, '₹')}
                    className='col-md-12'
                  />
                </div>
              </div>
              <div className='col-md-4'>
                {showFilter ? (
                  <div className='card card-filter'>
                    <ul className='list-group list-group-flush'>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('today')}
                      >
                        24 hours
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('one_week')}
                      >
                        Past one week
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('one_month')}
                      >
                        Past one month
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() =>
                          this.setState((prevState) => ({
                            ...prevState,
                            showDatePicker: !prevState.showDatePicker,
                          }))
                        }
                      >
                        Choose date
                      </li>
                    </ul>
                  </div>
                ) : null}
                {showDatePicker && showFilter ? (
                  <div className='card date-picker'>
                    <div className='row'>
                      <div className='col mt-5'>
                        Start Date
                        <input
                          className='date'
                          type='date'
                          onChange={(e) =>
                            this.setState({
                              startDate: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className='col mt-5'>
                        End Date
                        <input
                          className='date'
                          type='date'
                          onChange={(e) =>
                            this.setState({
                              endDate: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='row mt-3 ml-2'>
                      <Button onClick={() => this.getDateFilterData()}>
                        OK
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='card'>
            <div className='row'>
              <div className='col-5 mt-5'>
                <form onSubmit={(e) => this.searchHandler(e)}>
                  <div className='search-form'>
                    <select
                      className='select '
                      onChange={this.handleChange}
                      name='searchBy'
                      required
                    >
                      <option disabled selected>
                        Search By
                      </option>
                      <option value={'userId'}>User Id</option>
                      <option value={'mobileNumber'}>
                        Registered Mobile Number
                      </option>
                      <option value={'invoiceNumber'}>Invoice No</option>
                      <option value={'uniqueInvoiceId'}>Invoice Id</option>
                    </select>

                    <input
                      className='select input'
                      required={true}
                      placeholder='Search'
                      name='searchValue'
                      onChange={this.handleChange}
                    />
                    <button type='submit' className='submit'>
                      <img src={SearchIcon} alt='icon' />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className='row'>
              <h3 className='col-md-10 card_heading_secondary'>
                Drafted Invoice
              </h3>
            </div>
            <ReactTable
              data={invoiceDetails}
              columns={draftedInvoicesColumn}
              defaultPageSize={10}
              showPagination={false}
              sortable={false}
            />
            <ul className={'datatable-pagination'}>
              <li>
                <button
                  className={'prev-btn'}
                  type={'button'}
                  disabled={!(currentPage > 1)}
                  onClick={() => this.getPageData('prev', numberOfPages)}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {numberOfPages === 0 ? 0 : currentPage} of{' '}
                  {numberOfPages}
                </span>
              </li>

              <li>
                <button
                  disabled={!(numberOfPages > currentPage)}
                  className={'next-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('next', numberOfPages)}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
          <CredoModal
            isOpen={this.state.isModalOpen}
            styles={{
              content: {
                minWidth: '60vw',
                height: 'auto',
              },
            }}
            closeAction={() => this.toggleModal('')}
            paymentModalData={paymentModalData}
            RenderingComponent={modalType}
            invoiceModalDetails={invoiceModalDetails}
            toggleModal={this.toggleModal}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ DraftedReducer }) => ({
  draftedList: DraftedReducer.draftedList,
  draftedSummary: DraftedReducer.draftedSummary,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getDraftedList,
      getDraftedSummary,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DraftedInvoices);

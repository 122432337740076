import React, { Component } from 'react';
import InvoiceButton from '../../../assets/images/invoice-button.png';
import SearchIcon from '../../../assets/images/SearchIcon.svg';
import Plus from '../../../assets/images/PlusUploadBank.png';
import Excel from '../../../assets/images/ExcelNewIcon.png';
import ReactTable from 'react-table';
import Button from '../../../components/Button/index';
import CheckBox from '../../../components/CheckBox/CheckBox';
import CredoModal from '../../../components/CredoModal';
import InvoiceExcelModal from './InvoiceExcelModal';
import InvoiceBankFeedbackModal from './InvoiceBankFeedbackModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import paymentMarginModal from '../PreviewPaymentModal';
import FundPendingModal from './FundPendingModal';
import RejectInvoices from './InvoiceRejectedModal';
import DetailButton from '../../../assets/images/detailicon.png';
import LenderPushInvoice from './LenderPushInvoice';
import {
  getInvoiceList,
  getInvoiceSummary,
} from './fundPendingInvoices.action';
import {
  API_POST_DOWNLOAD_DISBURSED_INVOICES,
  API_POST_INVOICE_DETAILS,
  API_POST_APPROVE_BANK_INVOICES,
  API_GET_LOAN_INFO,
  API_GET_REJECT_INVOICES,
  API_POST_SEND_INVOICE_LENDER,
} from '../../../utils/APIUrls';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { GET, POST } from '../../../utils/webAPI.service';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { loaderModal } from '../../../store/actions';
import {
  STATUS_PARAMS,
  STATUS_PARAMS_VALUE_MAP,
} from '../ALLInvoices/AllInvoices.config';
import TextLabel from '../../../components/TextLabel';

class FundPendingInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false,
      showFilter: false,
      showDatePicker: false,
      isModalOpen: false,
      modalType: '',
      invoicesTableData: [],
      selectedInvoices: [],
      invoiceModalDetails: {},
      currentPage: 1,
      endDate: null,
      startDate: null,
      duration: null,
      paymentModalData: {},
    };
  }

  getPageData = (type, totalPages) => {
    let { currentPage } = this.state;
    if (type === 'next' && currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    } else if (type === 'prev' && currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value === 'Status') {
      this.setState((prevState) => ({
        ...prevState,
        showStatus: !prevState.showStatus,
        [name]: value,
      }));
    } else {
      this.setState({
        [name]: value?.trim(),
        showStatus: false,
      });
    }
  };

  handleSearch = async (e) => {
    const { name, value } = e.target;
    await this.setState({
      [name]: value,
      currentPage: 1,
      searchValue: value,
    });
    let { duration, searchBy, search } = this.state;
    let { actions } = this.props;
    let { getInvoiceList } = actions;
    let dataToSend = {
      duration,
      endDate: null,
      numberOfRows: 10,
      pageNumber: 1,
      searchParam: searchBy,
      searchValue: search,
      startDate: null,
      type: 'funds_pending',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    loaderModal(false);
    this.setState({
      invoicesTableData: this.props?.fundPendingInvoicesList?.invoiceDetails,
    });
  };

  getPageChangeInvoiceData = async () => {
    let { actions } = this.props;
    let { duration, currentPage, searchBy, searchValue, endDate, startDate } =
      this.state;
    let { getInvoiceList, loaderModal } = actions;
    let dataToSend = {
      duration,
      endDate: endDate?.split('-')?.reverse()?.join('-'),
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: startDate?.split('-')?.reverse()?.join('-'),
      type: 'funds_pending',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    loaderModal(false);
    this.setState({
      invoicesTableData: this.props?.fundPendingInvoicesList?.invoiceDetails,
    });
  };

  searchHandler = async (e) => {
    e.preventDefault();
    let { searchBy, searchValue, duration } = this.state;
    let { actions } = this.props;
    let { getInvoiceList, loaderModal } = actions;
    await this.setState({
      currentPage: 1,
    });
    let dataToSend = {
      duration,
      endDate: null,
      numberOfRows: 10,
      pageNumber: 1,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: null,
      type: 'funds_pending',
    };
    if (searchBy === undefined) {
      alert('Please select search type');
      return;
    }
    loaderModal(true);
    await getInvoiceList(dataToSend);
    loaderModal(false);
    this.setState({
      invoicesTableData: this.props?.fundPendingInvoicesList?.invoiceDetails,
    });
  };

  toggleModal = (
    modalType,
    invoiceId,
    userId,
    invoiceAmount,
    fundedAmount,
    subventionAmount,
    marginAmount
  ) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      userId,
      paymentModalData: {
        invoiceId,
        invoiceAmount,
        fundedAmount,
        subventionAmount,
        marginAmount,
      },
    });
    let dataToSend = {
      invoiceId,
      userId,
    };
    if (invoiceId && modalType === FundPendingModal) {
      POST(API_POST_INVOICE_DETAILS, dataToSend)
        .then(({ data }) => {
          if (data.code === 200) {
            this.setState({
              invoiceModalDetails: data,
            });
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  rejectInvoice = (invoiceId) => {
    let { actions } = this.props;
    let { endDate, startDate, currentPage } = this.state;
    let { getInvoiceList, loaderModal } = actions;
    let dataToSend = {
      endDate: endDate?.split('-')?.reverse()?.join('-'),
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: null,
      searchValue: null,
      startDate: startDate?.split('-')?.reverse()?.join('-'),
      type: 'funds_pending',
    };
    loaderModal(true);
    if (invoiceId) {
      POST(API_GET_REJECT_INVOICES(invoiceId))
        .then(async ({ data }) => {
          if (data.code === 200) {
            await getInvoiceList(dataToSend);
            this.toggleModal('');
            await this.setState({
              invoicesTableData:
                this.props?.fundPendingInvoicesList?.invoiceDetails,
            });
            loaderModal(false);
          } else {
            alert(data?.message);
            this.toggleModal('');
          }
        })
        .catch((err) => {
          loaderModal(false);
          throw err;
        });
    }
    loaderModal(false);
  };

  getFilterData = async (duration) => {
    await this.setState((prevState) => ({
      ...prevState,
      showFilter: !prevState.showFilter,
      duration,
      currentPage: 1,
    }));
    this.invoiceData();
  };

  getDateFilterData = async () => {
    let { actions } = this.props;
    let { endDate, startDate, currentPage } = this.state;
    let { getInvoiceList, getInvoiceSummary } = actions;
    let dataToSend = {
      endDate: endDate?.split('-')?.reverse()?.join('-'),
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: null,
      searchValue: null,
      startDate: startDate?.split('-')?.reverse()?.join('-'),
      type: 'funds_pending',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    await getInvoiceSummary(dataToSend);
    loaderModal(false);
    this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
      showFilter: !prevState.showFilter,
      currentPage: 1,
      invoicesTableData: this.props?.fundPendingInvoicesList?.invoiceDetails,
    }));
  };

  invoiceData = async () => {
    let { actions } = this.props;
    let { duration, currentPage, searchBy, searchValue } = this.state;
    let { getInvoiceList, getInvoiceSummary, loaderModal } = actions;
    let dataToSend = {
      duration: duration || 'till_now',
      endDate: null,
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: null,
      type: 'funds_pending',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    await getInvoiceSummary(dataToSend);
    loaderModal(false);

    await this.setState({
      invoicesTableData: this.props?.fundPendingInvoicesList?.invoiceDetails,
    });
  };

  componentDidMount = async () => {
    await this.invoiceData();
  };

  reviewSingleInvoice = (invoiceId, userId) => {
    const dataToSend = {
      invoiceId,
      invoiceStatus: 'pending',
      userId,
    };
    POST(API_POST_APPROVE_BANK_INVOICES, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            alert('Successfully updated');
          }
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  sendInvoiceLender = (invoiceId, userId) => {
    POST(API_POST_SEND_INVOICE_LENDER(userId, invoiceId))
      .then(({ data }) => {
        if (data?.code === 200) {
          alert(`Invoice ${invoiceId} sent successfully to the lender.`);
          this.invoiceData();
          this.toggleModal('');
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  handleChange = () => {
    const { invoicesTableData } = this.state;
    let isAllSelected = invoicesTableData?.some(
      (invoicesData) => invoicesData?.isChecked !== true
    );
    let checkedValue = false;
    if (isAllSelected) {
      checkedValue = true;
    }
    let invoicesTableDataCopy = [...this.state.invoicesTableData];
    this.setState({
      invoicesTableData: invoicesTableDataCopy?.map((invoicesDataCopy) => ({
        ...invoicesDataCopy,
        isChecked: checkedValue,
      })),
    });
  };

  getLoanInfo = (userId) => {
    loaderModal(true);
    GET(API_GET_LOAN_INFO(userId))
      .then(({ data }) => {
        if (data?.code === 200) {
          if (data?.data) {
            setTimeout(function () {
              let newWindow = window.open(
                `/pay-app/${data?.data?.loanId}/${userId}/application/overview`
              );
              newWindow.focus();
            }, 350);
            loaderModal(false);
          }
        } else {
          alert(data?.message);
          loaderModal(false);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
    loaderModal(false);
  };

  downloadExcel = () => {
    const { invoicesTableData } = this.state;
    let checkedInvoices = invoicesTableData
      ?.filter((item) => item?.isChecked === true)
      .map((e) => e?.invoiceId);
    if (checkedInvoices?.length === 0) {
      alert('Please select the invoice to download excel');
      return;
    }
    const dataToSend = {
        invoiceIds: checkedInvoices,
      },
      token = localStorage.getItem('token');
    const headers = {
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': localStorage.getItem('userId'),
      Authorization: `Bearer ${token}`,
    };
    axios({
      url: `${API_POST_DOWNLOAD_DISBURSED_INVOICES}`,
      method: 'POST',
      headers: headers,
      responseType: 'blob',
      data: dataToSend,
    }).then((res) => {
      FileDownload(res.data, `disbursed_invoices.xlsx`);
    });
  };
  render() {
    let {
      showStatus,
      showFilter,
      showDatePicker,
      invoicesTableData,
      modalType,
      invoiceModalDetails,
      currentPage,
      userId,
      paymentModalData,
    } = this.state;
    let { fundPendingInvoicesList, fundPendingInvoicesSummary } = this.props;
    let { numberOfPages } = fundPendingInvoicesList || {};
    let { summary } = fundPendingInvoicesSummary || {};
    let { bank_failed, funds_pending, to_be_processed, under_processing } =
      summary || {};
    const fundPendingInvoicesColumn = [
      {
        Header: () => {
          let isAllSelected = invoicesTableData?.some(
            (invoicesData) => invoicesData?.isChecked !== true
          );

          return (
            <CheckBox
              checkboxColor={'#ADADAD'}
              htmlFor={'filter1'}
              inputClass={''}
              labelClass={''}
              checked={!isAllSelected}
              onChange={this.handleChange}
            />
          );
        },
        accessor: 'isChecked',
        width: 60,
        Cell: ({ value, index }) => {
          const onCheckChange = () => {
            let invoicesTableDataCopy = [...invoicesTableData];
            invoicesTableDataCopy[index] = {
              ...invoicesTableDataCopy[index],
              isChecked: !value,
            };
            this.setState({ invoicesTableData: invoicesTableDataCopy });
          };
          return (
            <div>
              {' '}
              <CheckBox
                checkboxColor={'#ADADAD'}
                htmlFor={'filter1'}
                inputClass={''}
                labelClass={''}
                checked={value}
                onChange={onCheckChange}
              />
            </div>
          );
        },
      },
      {
        Header: 'User Id',
        accessor: 'userId',
        width: 120,
        Cell: (props) => {
          let { original } = props;
          let { userId } = original;
          return (
            <>
              {
                <div
                  className='invoice-pointer'
                  onClick={() => this.getLoanInfo(userId)}
                >
                  {userId || 'NA'}
                </div>
              }
            </>
          );
        },
      },
      {
        Header: 'Invoice Id',
        accessor: 'invoiceId',
        width: 160,
        Cell: (props) => {
          let { original } = props || {};
          let { disbursalStatus } = original;
          return (
            <>
              <div className='right-aligned pr-5'>{original?.invoiceId}</div>
              {disbursalStatus === STATUS_PARAMS.IN_REVIEW ? (
                <span className='capsule ml-4 capsule_orange'>
                  {STATUS_PARAMS_VALUE_MAP.IN_REVIEW}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.PROCESSING ? (
                <span className='capsule  ml-4  capsule_orange'>
                  {STATUS_PARAMS_VALUE_MAP.PROCESSING}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.PROCESSED ? (
                <span className='capsule ml-4 capsule_new_green'>
                  {STATUS_PARAMS_VALUE_MAP.PROCESSED}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.ON_HOLD ? (
                <span className='capsule ml-4 capsule_grey'>
                  {STATUS_PARAMS_VALUE_MAP.ON_HOLD}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.FAILED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.FAILED}{' '}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.REJECTED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.REJECTED}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.BANK_FAILED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.BANK_FAILED}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.DRAFT ? (
                <span className='capsule ml-4 capsule_blue'>
                  {STATUS_PARAMS_VALUE_MAP.DRAFT}
                </span>
              ) : (
                <span className='capsule ml-4 capsule_blue'>
                  {disbursalStatus}
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
        width: 160,
      },
      {
        Header: () => (
          <>
            <div>Transaction</div>
            <div>Date</div>
          </>
        ),
        accessor: 'transactionDate',
        width: 100,
        Cell: (props) => {
          let { original } = props;
          let { transactionDate } = original;
          return (
            <>
              <div>{transactionDate || 'NA'}</div>
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            <div className='ml-4'>Invoice</div>
            <div className='ml-4'>Amount</div>
          </>
        ),
        accessor: 'invoiceAmount',
        width: 160,
        Cell: (props) => {
          let { original } = props;
          let { invoiceAmount } = original;

          return (
            <>
              <div className='ml-4'>
                {rupeeDecimalFormatter(invoiceAmount, '₹')}
              </div>
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            <div>Payment</div>
            <div>Amount</div>
          </>
        ),
        accessor: 'fundedAmount',
        width: 180,
        Cell: (props) => {
          let { original } = props;
          let {
            fundedAmount,
            invoiceId,
            userId,
            invoiceAmount,
            subventionAmount,
            marginAmount,
          } = original;

          return (
            <div className=' pr-5 invoices-wrapper d-flex justify-content-between'>
              {rupeeDecimalFormatter(fundedAmount, '₹')}
              <img
                className='preview-margin-modal'
                alt='icon'
                src={DetailButton}
                onClick={() =>
                  this.toggleModal(
                    paymentMarginModal,
                    invoiceId,
                    userId,
                    invoiceAmount,
                    fundedAmount,
                    subventionAmount,
                    marginAmount
                  )
                }
              />
            </div>
          );
        },
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: (props) => {
          let { original } = props;
          let { invoiceId, userId } = original;

          return (
            <button
              className='details_button ml-2'
              onClick={() =>
                this.toggleModal(FundPendingModal, invoiceId, userId)
              }
            >
              Details
            </button>
          );
        },
      },
      {
        Header: 'Action',
        Cell: ({ original }) => {
          const { userId, invoiceId, disbursalStatus, pushedToLender } =
            original || {};
          return (
            <>
              <select
                className='dropdown'
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  if (
                    selectedValue === 'pending' &&
                    disbursalStatus === STATUS_PARAMS.BANK_FAILED
                  ) {
                    this.reviewSingleInvoice(invoiceId, userId);
                  } else if (
                    selectedValue === 'reject' &&
                    disbursalStatus === STATUS_PARAMS.PROCESSING
                  ) {
                    this.toggleModal(RejectInvoices, invoiceId, userId);
                  } else if (selectedValue === 'push') {
                    this.toggleModal(LenderPushInvoice, invoiceId, userId);
                  }
                }}
              >
                <option disabled selected>
                  Select
                </option>
                {disbursalStatus === STATUS_PARAMS.BANK_FAILED && (
                  <option value='pending'>To be processed</option>
                )}
                {disbursalStatus === STATUS_PARAMS.PROCESSING && (
                  <option value='reject'>Reject</option>
                )}
                {/* The value true should be used to restrict the action. Otherwise invoice action should be allowed for values false and null. */}
                {disbursalStatus === STATUS_PARAMS.PROCESSING && (
                  <option disabled={pushedToLender} value='push'>
                    Send to Lender
                  </option>
                )}
              </select>
            </>
          );
        },
      },
    ];

    return (
      <>
        <div className='container invoices-wrapper'>
          <div className='card'>
            <div className='invoice-header'>
              <h3 className='card_heading'>INVOICE SUMMARY</h3>
              <img
                onClick={() =>
                  this.setState((prevState) => ({
                    ...prevState,
                    showFilter: !prevState.showFilter,
                  }))
                }
                alt='filter'
                className='invoice-btn mt-5'
                src={InvoiceButton}
              />
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <div className='row'>
                  <TextLabel
                    label='All Fund Pending Invoices'
                    value={funds_pending?.count}
                    className='col-md-3'
                  />
                  <TextLabel
                    label='To Be Processed Invoices'
                    value={to_be_processed?.count}
                    className='col-md-3'
                  />
                  <TextLabel
                    label='Under Processing Invoice'
                    value={under_processing?.count}
                    className='col-md-3'
                  />
                  <TextLabel
                    label='Bank Failed Invoice'
                    value={bank_failed?.count}
                    className='col-md-3'
                  />
                  <TextLabel
                    label='Total Amount'
                    value={rupeeDecimalFormatter(funds_pending?.amount, '₹')}
                    className='col-md-3'
                  />
                  <TextLabel
                    label='Total Amount'
                    value={rupeeDecimalFormatter(to_be_processed?.amount, '₹')}
                    className='col-md-3'
                  />
                  <TextLabel
                    label='Total Amount'
                    value={rupeeDecimalFormatter(under_processing?.amount, '₹')}
                    className='col-md-3'
                  />
                  <TextLabel
                    label='Total Amount'
                    value={rupeeDecimalFormatter(bank_failed?.amount, '₹')}
                    className='col-md-3'
                  />
                </div>
              </div>
              <div className='col-md-4'>
                {showFilter ? (
                  <div className='card card-filter'>
                    <ul className='list-group list-group-flush'>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('today')}
                      >
                        24 hours
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('one_week')}
                      >
                        Past one week
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('one_month')}
                      >
                        Past one month
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() =>
                          this.setState((prevState) => ({
                            ...prevState,
                            showDatePicker: !prevState.showDatePicker,
                          }))
                        }
                      >
                        Choose date
                      </li>
                    </ul>
                  </div>
                ) : null}
                {showDatePicker && showFilter ? (
                  <div className='card date-picker'>
                    <div className='row'>
                      <div className='col mt-5'>
                        Start Date
                        <input
                          className='date'
                          type='date'
                          onChange={(e) =>
                            this.setState({
                              startDate: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className='col mt-5'>
                        End Date
                        <input
                          className='date'
                          type='date'
                          onChange={(e) =>
                            this.setState({
                              endDate: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='row mt-3 ml-2'>
                      <Button onClick={() => this.getDateFilterData()}>
                        OK
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='card row'>
            <div className='row'>
              <div className='col-5 mt-5'>
                <form onSubmit={(e) => this.searchHandler(e)}>
                  <div className='search-form '>
                    <select
                      className='select search-status '
                      onChange={this.handleInputChange}
                      name='searchBy'
                      required
                    >
                      <option disabled selected>
                        Search By
                      </option>
                      <option value={'userId'}>User Id</option>
                      <option value={'mobileNumber'}>
                        Registered Mobile Number
                      </option>
                      <option value={'invoiceNumber'}>Invoice No</option>
                      <option value={'uniqueInvoiceId'}>Invoice Id</option>
                      <option value={'Status'}>status</option>
                    </select>
                    {showStatus ? (
                      <select
                        className='select drop-search '
                        onChange={this.handleSearch}
                        name='search'
                        required
                      >
                        <option disabled selected>
                          Select
                        </option>

                        <option value={'Under Processing'}>
                          Under Processing
                        </option>
                        <option value={'To be processed'}>
                          To be Processed
                        </option>
                        <option value={'Bank Failed'}>Bank Failed</option>
                      </select>
                    ) : (
                      <>
                        <input
                          className='select input'
                          required={true}
                          placeholder='Search'
                          name='searchValue'
                          onChange={this.handleInputChange}
                        />
                        <button type='submit' className='submit'>
                          <img src={SearchIcon} alt='icon' />
                        </button>
                      </>
                    )}
                  </div>
                </form>
              </div>
              <div className='col d-flex justify-content-end '>
                <button
                  className='btn btn-text button add_button button_invoices px-4'
                  onClick={() => this.toggleModal(InvoiceBankFeedbackModal)}
                >
                  <img src={Plus} alt='icon' />
                  <span>Upload Bank Feedback</span>
                </button>
                <button
                  className='btn btn-text button add_button button_invoices btn-green px-4'
                  onClick={() => this.toggleModal(InvoiceExcelModal)}
                >
                  <img src={Excel} alt='icon' />
                  <span>Download Excel</span>
                </button>
              </div>
            </div>
            <h3 className=' card_heading_secondary'>Fund Pending Invoice</h3>
            <ReactTable
              data={invoicesTableData}
              columns={fundPendingInvoicesColumn}
              defaultPageSize={10}
              showPagination={false}
              sortable={false}
            />
            <ul className={'datatable-pagination'}>
              <li>
                <button
                  disabled={!(currentPage > 1)}
                  className={'prev-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('prev', numberOfPages)}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {numberOfPages === 0 ? 0 : currentPage} of{' '}
                  {numberOfPages}
                </span>
              </li>

              <li>
                <button
                  disabled={!(numberOfPages > currentPage)}
                  className={'next-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('next', numberOfPages)}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>

          <CredoModal
            isOpen={this.state.isModalOpen}
            styles={{
              content: {
                width:
                  modalType === InvoiceExcelModal ||
                  modalType === InvoiceBankFeedbackModal
                    ? '24vw'
                    : modalType === RejectInvoices ||
                      modalType === LenderPushInvoice
                    ? '60rem'
                    : modalType === paymentMarginModal
                    ? '60rem'
                    : '60vw',
                height:
                  modalType === InvoiceExcelModal ||
                  modalType === InvoiceBankFeedbackModal
                    ? '20vw'
                    : modalType === RejectInvoices ||
                      modalType === LenderPushInvoice
                    ? '30rem'
                    : modalType === paymentMarginModal
                    ? '40rem'
                    : 'auto',
              },
            }}
            closeAction={() => this.toggleModal('')}
            invoiceModalDetails={invoiceModalDetails}
            RenderingComponent={modalType}
            toggleModal={this.toggleModal}
            paymentModalData={paymentModalData}
            rejectInvoice={this.rejectInvoice}
            sendInvoiceLender={this.sendInvoiceLender}
            stateData={this.state}
            userId={userId}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ FundPendingInvoicesReducer }) => ({
  fundPendingInvoicesList: FundPendingInvoicesReducer.fundPendingInvoicesList,
  fundPendingInvoicesSummary:
    FundPendingInvoicesReducer.fundPendingInvoicesSummary,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvoiceList,
      getInvoiceSummary,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundPendingInvoices);

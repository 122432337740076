import React, { Component } from 'react';

export default class UserProfileModal extends Component {
  render() {
    let { propsData } = this.props || {},
      { photos, promoterData } = propsData || {},
      { selfieAnalysis
      } = photos || {};
    return (
      <div className='compare-details-wrapper application-wrapper'>
        <h3 className='card_heading'>{promoterData?.fullName}</h3>
        <div className='row m-20'>
          <div className='col-md-4 '>
            <div className='entity_container_heading'>Liveness Score</div>
            <div className='entity_container_value'>
            {selfieAnalysis?.digio?.score ?? '-'}
            </div>
          </div>
          <div className='col-md-4'>
            <div className='entity_container_heading'>Liveness Result</div>
            <div className='entity_container_value'>
              {' '}
              {selfieAnalysis?.digio?.result || '-'}
            </div>
          </div>
       
          <div className='col-md-4'>
            <div className='entity_container_heading'>Selfie Confidence</div>
            <div className='entity_container_value'>
               {selfieAnalysis?.confidence || '-'} 
            </div>
          </div>
          <div className='col-md-4 mt-3'>
            <div className='entity_container_heading'>Eyes Open Confidence</div>
            <div className='entity_container_value'>
               {selfieAnalysis?.eyesOpen?.confidence || '-'} 
            </div>
          </div>
          <div className='col-md-4 mt-3'>
            <div className='entity_container_heading'>Eyes Open Status</div>
            <div className='entity_container_value'>
            {selfieAnalysis?.eyesOpen?.value === true ? 'Pass' : selfieAnalysis?.eyesOpen?.value === false ? 'Fail' : '-'}
            </div>
          </div>
          <div className='col-md-4 mt-3'>
            <div className='entity_container_heading'>Brightness
            </div>
            <div className='entity_container_value'>
               {selfieAnalysis?.quality?.brightness || '-'} 
            </div>
          </div>
          <div className='col-md-4 mt-3'>
            <div className='entity_container_heading'>Face Visibility Confidence</div>
            <div className='entity_container_value'>
               {selfieAnalysis?.faceOccluded?.confidence || '-'} 
            </div>
          </div>
          <div className='col-md-4 mt-3'>
            <div className='entity_container_heading'>Face Visibility Status</div>
            <div className='entity_container_value'>
            {selfieAnalysis?.faceOccluded?.value === true ? 'Fail' : selfieAnalysis?.faceOccluded?.value === false ? 'Pass' : '-'}
            </div>
          </div>
       
          <div className='col-md-4 mt-3'>
            <div className='entity_container_heading'>Sharpness</div>
            <div className='entity_container_value'>
               {selfieAnalysis?.quality?.sharpness || '-'} 
            </div>
          </div>
          <div className='col-md-12 mt-3'>
            <div className='entity_container_heading'>Error</div>
            <div className='entity_container_value'>
               {selfieAnalysis?.digio?.errors[0] || '-'} 
            </div>
          </div>
        </div>
        <img
          src={photos?.profileDocument?.fileUrl}
          alt='user_profile'
          className='promoter_img'
        />
      </div>
    );
  }
}

import React, { Component } from 'react';
import { rupeeDecimalFormatter } from '../../utils/utility';
export default class paymentMarginModal extends Component {
  render() {
    let { paymentModalData } = this.props || {};
    let {
      fundedAmount,
      invoiceAmount,
      invoiceId,
      marginAmount,
      subventionAmount,
    } = paymentModalData || {};

    const paymentDetails = [
      { label: 'Invoice Amount', value: invoiceAmount },
      { label: 'Subvention Amount', value: subventionAmount },
      { label: 'Margin Amount', value: marginAmount },
      { label: 'Payment Amount', value: fundedAmount },
    ];
    return (
      <>
        <div className='modal-wrapper manage_user-wrapper ml-3'>
          <h3 className='card_heading'>Invoice Id : {invoiceId} </h3>
          {paymentDetails.map(({ label, value }, index) => (
            <div className='row' key={index}>
              <div className='col-md-4 text-grey'>{label} :</div>
              <span className='text-bold mt-1'>
                {rupeeDecimalFormatter(value, '₹') ?? '-'}
              </span>
            </div>
          ))}
        </div>
      </>
    );
  }
}

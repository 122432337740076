import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Helmet } from 'react-helmet';
import Button from '../../../components/Button';
import { API_POST_UPDATE_USER_STATUS } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';
import CredoModal from '../../../components/CredoModal';
import AddSupplierModal from './addSupplierModal';
import AddCustomerModal from './addCustomerModal';
import addUserIcon from '../../../assets/images/user-plus (5) 1.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Preview from '../../../assets/images/eye.png';
import {
  getInvoiceSummary,
  getCustomerAndSupplierList,
  getFundingSummary,
} from './manageUser.actions';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import DownArrow from '../../../assets/images/DownArrow.svg';
import UpArrow from '../../../assets/images/UpArrow.svg';
import { loaderModal } from '../../../store/actions';
import Wavier from '../../../assets/images/wavier.svg';
import SupplierDetailsModal from './SupplierDetailsModal';
import VendorOffer from './VendorLimit';
import TextLabel from '../../../components/TextLabel';
class ManagerUser extends Component {
  state = {
    activeFigure: 'supplier',
    isModalOpen: false,
    sortName: -1,
    sortDate: -1,
    currentPageNum: 1,
  };

  componentDidMount = async () => {
    let { match, actions } = this.props,
      { userId } = match.params,
      {
        getInvoiceSummary,
        getCustomerAndSupplierList,
        getFundingSummary,
        loaderModal,
      } = actions,
      token = localStorage.getItem('token');
    loaderModal(true);
    if (token) {
      await getInvoiceSummary(userId);
      getFundingSummary(userId);
      getCustomerAndSupplierList(userId, 'supplier', 1);
      loaderModal(false);
    } else {
      let { history } = this.props;
      alert('Unauthorized access');
      actions.loaderModal(false);
      history.push('/');
    }
  };

  toggleModal = (name, rowData) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType: name,
      rowData,
    });
  };

  userHandler = async (type) => {
    let { match, actions } = this.props,
      { userId } = match.params,
      { getCustomerAndSupplierList, loaderModal } = actions;
    loaderModal(true);
    if (type === 'supplier') {
      await getCustomerAndSupplierList(userId, 'supplier', 1);
    } else {
      await getCustomerAndSupplierList(userId, 'customer', 1);
    }
    loaderModal(false);
    this.setState({ activeFigure: type });
  };

  statusChangeHandler = (e, userContactMobileNumber, contactType) => {
    let { match, actions } = this.props,
      { params } = match,
      { userId } = params,
      { value } = e.target;
    let dataToSend = {
      contactType,
      status: value,
      userContactMobileNumber,
      userId,
    };

    let { getCustomerAndSupplierList } = actions;

    POST(API_POST_UPDATE_USER_STATUS, dataToSend)
      .then(async ({ data }) => {
        if (data.code === 200) {
          await getCustomerAndSupplierList(
            userId,
            'supplier',
            this.state.currentPageNum
          );
          alert('Status updated sucessfully');
        } else {
          await getCustomerAndSupplierList(
            userId,
            'supplier',
            this.state.currentPageNum
          );
          alert(data?.message || 'Something went wrong');
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  getPageData = async (type, list, contactType) => {
    let { currentPage, totalPage } = list,
      { actions, match } = this.props,
      { userId } = match.params,
      { sortDate, sortName, sortingOn } = this.state;
    actions.loaderModal(true);
    if (type === 'prev' && currentPage > 1) {
      await this.setState({ currentPageNum: currentPage - 1 });
    } else if (type === 'next' && currentPage < totalPage) {
      await this.setState({ currentPageNum: currentPage + 1 });
    }
    await actions.getCustomerAndSupplierList(
      userId,
      contactType,
      this.state.currentPageNum,
      sortingOn === 'name' ? sortName : sortDate,
      sortingOn
    );
    actions.loaderModal(false);
  };

  getTheadThProps = (state, rowInfo, column) => ({
    onClick: async () => {
      let { sortDate, sortName, currentPageNum, activeFigure } = this.state,
        { actions, match } = this.props,
        { userId } = match.params;

      if (column?.id === 'name') {
        await this.setState({
          sortName: sortName === -1 ? 1 : -1,
          sortDate: -1,
          sortingOn: 'name',
        });
      } else if (column?.id === 'createdAt') {
        await this.setState({
          sortDate: sortDate === -1 ? 1 : -1,
          sortName: -1,
          sortingOn: 'createdDate',
        });
      }
      actions.getCustomerAndSupplierList(
        userId,
        activeFigure,
        currentPageNum,
        this.state.sortingOn === 'name'
          ? this.state.sortName
          : this.state.sortDate,
        this.state.sortingOn
      );
    },
  });
  render() {
    let { activeFigure, isModalOpen, modalType, sortDate, sortName } =
        this.state,
      { invoiceSummary, customerList, supplierList, fundingSummary } =
        this.props,
      {
        supplierTotalInvoices,
        supplierOutstandingInvoiceCount,
        customerTotalInvoices,
        customerOutstandingInvoiceCount,
      } = invoiceSummary,
      {
        totalAmountPaidToSuppliers,
        lastTransactionDateSupplier,
        totalAmountPaidToSelf,
        lastTransactiondateCustomer,
      } = fundingSummary;

    const supplierColumns = [
      {
        Header: 'Supplier ID',
        sortable: false,
        filterable: false,
        Cell: ({ original }) => {
          return <div className='user_id'>{original?.uniqueId}</div>;
        },
      },
      {
        Header: () => (
          <span className='pr-5'>
            Supplier Name
            <img
              src={sortName === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'name',
        Cell: ({ original }) => {
          return (
            <div className='avatar_container'>
              <div className='avatar_container_icon'>{original?.name[0]}</div>
              <div>{original?.name}</div>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='pr-5'>
            Onboarded Date
            <img
              src={sortDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'createdAt',
      },
      {
        Header: 'Phone No',
        accessor: 'mobileNumber',
        sortable: false,
        filterable: false,
      },

      {
        Header: ' Status',
        sortable: false,
        filterable: false,
        Cell: ({ original }) => {
          let statusArray,
            newStatusArray,
            { mobileNumber } = original;
          if (original.status === 'Active' || original.status === 'Inactive') {
            statusArray = [{ status: 'Active' }, { status: 'Inactive' }];
            newStatusArray = statusArray.filter(
              ({ status }) => status !== original.status
            );
          } else if (original.status === 'Pending') {
            statusArray = [
              { status: 'Active' },
              { status: 'Inactive' },
              { status: 'Pending' },
            ];
            newStatusArray = statusArray.filter(
              ({ status }) => status !== original.status
            );
          }
          return (
            <select
              onChange={(e) =>
                this.statusChangeHandler(e, mobileNumber, 'supplier')
              }
              className={`user_select  ${original?.status}`}
            >
              <option selected>{original?.status}</option>
              {newStatusArray?.map((e) => {
                return <option>{e.status}</option>;
              })}
            </select>
          );
        },
      },
      {
        Header: '',
        sortable: false,
        filterable: false,
        width: 100,
        Cell: ({ original }) => {
          let { limitCreated } = original;
          let { supplierList } = this.props;
          let { userLimitCreated, programType } = supplierList;
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                {userLimitCreated && programType === 'buyer' ? (
                  <>
                    {limitCreated === false ? (
                      <button
                        className='button'
                        onClick={() => this.toggleModal(VendorOffer, original)}
                      >
                        +
                      </button>
                    ) : (
                      <img
                        className='ml-3'
                        onClick={() => this.toggleModal(VendorOffer, original)}
                        src={Preview}
                        alt='Preview_Vendor'
                      />
                    )}
                  </>
                ) : null}
              </span>
              <img
                src={Wavier}
                alt='icon'
                className='wavier_img'
                onClick={() => {
                  this.toggleModal(SupplierDetailsModal, original);
                }}
              />
            </div>
          );
        },
      },
    ];

    const customerColumns = [
      {
        Header: 'Customer ID',
        sortable: false,
        filterable: false,
        Cell: ({ original }) => {
          return <div className='user_id'>{original?.uniqueId}</div>;
        },
      },
      {
        Header: () => (
          <span className='pr-5'>
            Customer Name
            <img
              src={sortName === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'name',
        Cell: ({ original }) => {
          return (
            <div className='avatar_container'>
              <div className='avatar_container_icon'>{original?.name[0]}</div>
              <div>{original?.name}</div>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='pr-5'>
            Creation Date
            <img
              src={sortDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'createdAt',
      },
      {
        Header: 'Phone No',
        accessor: 'mobileNumber',
        sortable: false,
        filterable: false,
      },
      {
        Header: ' Status',
        sortable: false,
        filterable: false,
        Cell: ({ original }) => {
          let statusArray,
            newStatusArray,
            { mobileNumber } = original;
          if (original.status === 'Active' || original.status === 'Inactive') {
            statusArray = [{ status: 'Active' }, { status: 'Inactive' }];
            newStatusArray = statusArray.filter(
              ({ status }) => status !== original.status
            );
          } else if (original.status === 'Pending') {
            statusArray = [
              { status: 'Active' },
              { status: 'Inactive' },
              { status: 'Pending' },
            ];
            newStatusArray = statusArray.filter(
              ({ status }) => status !== original.status
            );
          }

          return (
            <select
              onChange={(e) =>
                this.statusChangeHandler(e, mobileNumber, 'customer')
              }
              className={`user_select  ${original?.status}`}
            >
              <option selected>{original?.status}</option>
              {newStatusArray?.map((e, index) => {
                return <option>{e.status}</option>;
              })}
            </select>
          );
        },
      },
    ];

    return (
      <>
        <div className='manage_user-wrapper container'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> Manager User</title>
          </Helmet>
          <div className='card'>
            <h3 className='card_heading'> Summary</h3>

            <div className='row generic_button_box'>
              <div
                onClick={() => this.userHandler('supplier')}
                className={
                  activeFigure === 'supplier'
                    ? 'col-md-1 generic_button_active'
                    : 'col-md-1 generic_button'
                }
              >
                Supplier
              </div>
              <div
                onClick={() => this.userHandler('customer')}
                className={
                  activeFigure === 'customer'
                    ? 'col-md-1 generic_button_active'
                    : 'col-md-1 generic_button'
                }
              >
                Customer
              </div>
            </div>
            {activeFigure === 'supplier' ? (
              <div className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <TextLabel
                      label='Total number of Suppliers'
                      value={supplierList?.totalMembersCount ?? '-'}
                      className='col-md-2'
                    />
                    <TextLabel
                      label='Total Invoices'
                      value={supplierTotalInvoices ?? '-'}
                      className='col-md-2'
                    />
                    <TextLabel
                      label='Total Outstanding Invoices'
                      value={supplierOutstandingInvoiceCount ?? '-'}
                      className='col-md-2'
                    />
                    <TextLabel
                      label='Total Paid'
                      value={rupeeDecimalFormatter(
                        totalAmountPaidToSuppliers,
                        '₹'
                      )}
                      className='col-md-2'
                    />
                    <TextLabel
                      label='Latest Transaction'
                      value={lastTransactionDateSupplier ?? '-'}
                      className='col-md-2'
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {activeFigure === 'customer' ? (
              <div className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <TextLabel
                      label='Total number of Customers'
                      value={customerList?.totalMembersCount ?? '-'}
                      className='col-md-2'
                    />
                    <TextLabel
                      label='Total Invoices'
                      value={customerTotalInvoices ?? '-'}
                      className='col-md-2'
                    />
                    <TextLabel
                      label='Total Outstanding Invoices'
                      value={customerOutstandingInvoiceCount ?? '-'}
                      className='col-md-2'
                    />
                    <TextLabel
                      label='Total Received'
                      value={rupeeDecimalFormatter(totalAmountPaidToSelf, '₹')}
                      className='col-md-2'
                    />
                    <TextLabel
                      label='Latest Transaction'
                      value={lastTransactiondateCustomer ?? '-'}
                      className='col-md-2'
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className='card'>
            {activeFigure === 'supplier' ? (
              <>
                <div className='row'>
                  <h3 className='col-md-10 card_heading_secondary'>Supplier</h3>
                  <h5
                    className='col-md-2  add_button m-button'
                    onClick={() => this.toggleModal(AddSupplierModal)}
                  >
                    <img src={addUserIcon} alt='' /> Add Supplier
                  </h5>
                </div>

                <ReactTable
                  data={supplierList?.userContactList || []}
                  columns={supplierColumns}
                  defaultPageSize={10}
                  showPagination={false}
                  getTrProps={this.rowClicked}
                  sortable={false}
                  getTheadThProps={this.getTheadThProps}
                />
                <ul className={'datatable-pagination'}>
                  <li>
                    <button
                      disabled={supplierList?.currentPage <= 1}
                      className={'prev-btn'}
                      type={'button'}
                      onClick={() =>
                        this.getPageData('prev', supplierList, 'supplier')
                      }
                    >
                      Previous
                    </button>
                  </li>
                  <li>
                    <span>
                      Page No. {supplierList?.currentPage} of
                      {'  '} {supplierList?.totalPage}
                    </span>
                  </li>
                  <li>
                    <button
                      disabled={
                        !(supplierList?.currentPage < supplierList?.totalPage)
                      }
                      className={'next-btn'}
                      type={'button'}
                      onClick={() =>
                        this.getPageData('next', supplierList, 'supplier')
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </>
            ) : null}
            {activeFigure === 'customer' ? (
              <>
                <div className='row'>
                  <h3 className='col-md-10 card_heading_secondary'>
                    Customers
                  </h3>
                  <h5
                    className='col-md-2  add_button m-button'
                    onClick={() => this.toggleModal(AddCustomerModal)}
                  >
                    <img src={addUserIcon} alt='icon' />
                    Add Customer
                  </h5>
                </div>

                <ReactTable
                  data={customerList?.userContactList}
                  columns={customerColumns}
                  defaultPageSize={10}
                  showPagination={false}
                  getTrProps={this.rowClicked}
                  sortable={false}
                  getTheadThProps={this.getTheadThProps}
                />
                <ul className={'datatable-pagination'}>
                  <li>
                    <button
                      disabled={customerList?.currentPage <= 1}
                      className={'prev-btn'}
                      type={'button'}
                      onClick={() =>
                        this.getPageData('prev', customerList, 'customer')
                      }
                    >
                      Previous
                    </button>
                  </li>
                  <li>
                    <span>
                      Page No. {customerList?.currentPage} of {'  '}
                      {customerList?.totalPage}
                    </span>
                  </li>
                  <li>
                    <button
                      disabled={
                        !(customerList?.currentPage < customerList?.totalPage)
                      }
                      className={'next-btn'}
                      type={'button'}
                      onClick={() =>
                        this.getPageData('next', customerList, 'customer')
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </>
            ) : null}
            <CredoModal
              isOpen={isModalOpen}
              styles={
                modalType === SupplierDetailsModal
                  ? {
                      content: {
                        minWidth: '80vw',
                        height: 'auto',
                      },
                    }
                  : modalType === VendorOffer
                  ? {
                      content: {
                        width: '70%',
                        height: 'max-content',
                      },
                    }
                  : {
                      content: {
                        display: 'table',
                        minWidth: '50vw',
                        height: 'auto',
                      },
                    }
              }
              closeAction={() => this.toggleModal('')}
              RenderingComponent={modalType}
              stateData={this.state}
              propsData={this.props}
              toggleModal={this.toggleModal}
              supplierList={supplierList}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ manageUserReducer }) => ({
  invoiceSummary: manageUserReducer.invoiceSummary,
  supplierList: manageUserReducer.supplierList,
  customerList: manageUserReducer.customerList,
  fundingSummary: manageUserReducer.fundingSummary,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvoiceSummary,
      getCustomerAndSupplierList,
      getFundingSummary,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerUser);

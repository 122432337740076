import React from 'react';

const TextLabel = ({ label, value, className = '' }) => {
  return (
    <div className={`text-grey ${className}`}>
      {label}
      <div className='text-bold'>{value ?? '-'}</div>
    </div>
  );
};

export default TextLabel;

import React, { Component } from 'react';
import { rupeeDecimalFormatter } from '../../../../../../utils/utility';
import TextLabel from '../../../../../../components/TextLabel';

export default class PaidInvoiceModal extends Component {
  render() {
    let { invoiceData } = this.props;
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Invoice Number : {invoiceData?.invoiceNumber}
        </h3>
        <div className='row'>
          <TextLabel
            label='Invoice Amount'
            value={rupeeDecimalFormatter(invoiceData?.invoiceAmount, '₹')}
            className='col-md-3'
          />
          <TextLabel
            label='Invoice Date'
            value={invoiceData?.invoiceDate ?? '-'}
            className='col-md-3'
          />
          <TextLabel
            label='Subvention Amount'
            value={rupeeDecimalFormatter(invoiceData?.subventionAmount, '₹')}
            className='col-md-3'
          />

          <TextLabel
            label='UTR Number'
            value={invoiceData?.utrNumber ?? '-'}
            className='col-md-3'
          />
          <TextLabel
            label='Margin Amount'
            value={rupeeDecimalFormatter(invoiceData?.marginAmount, '₹')}
            className='col-md-3'
          />
          <TextLabel
            label='Payment Date'
            value={invoiceData?.paymentDate ?? '-'}
            className='col-md-3'
          />
          <TextLabel
            label='Total Payment Amount'
            value={rupeeDecimalFormatter(invoiceData?.paymentAmount, '₹')}
            className='col-md-3'
          />
        </div>
        <div className='d-flex justify-content-center'>
          {invoiceData?.invoiceFileUrl?.includes('pdf') ||
          invoiceData?.invoiceFileUrl?.includes('PDF') ? (
            <iframe
              title='Invoice'
              src={invoiceData?.invoiceFileUrl}
              className='profile_img  preview_img'
            ></iframe>
          ) : (
            <img
              src={invoiceData?.invoiceFileUrl}
              alt='invoice_image'
              className='invoice_img'
            />
          )}
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import ReactTable from 'react-table';
import DownArrow from '../../../assets/images/downArrow.png';
import FileDownload from 'js-file-download';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loaderModal } from '../../../store/actions';
import { getEInvoiceData } from '../ALLInvoices/allInvoices.action';
import TextLabel from '../../../components/TextLabel';

export class DisbursedInvoiceModal extends Component {
  invoiceDownload = async () => {
    const { invoiceModalDetails } = this.props;
    const { data } = invoiceModalDetails || {};
    try {
      const response = await fetch(data?.invoiceFileUrl);
      const blob = await response.blob();
      FileDownload(blob, `invoice.jpeg`);
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {
    try {
      const { actions, paymentModalData } = this.props || {};
      const { invoiceId } = paymentModalData;
      const { getEInvoiceData } = actions;
      await getEInvoiceData(invoiceId);
    } catch (error) {
      console.error('error', error);
    }
  }
  render() {
    let { invoiceModalDetails, eInvoices } = this.props;
    let { data } = invoiceModalDetails || {};
    let { paymentDetails, invoiceFileUrl } = data || [];
    const paymentDetailsArray = paymentDetails ? [paymentDetails] : [];

    const outstandingInvoiceColumns = [
      {
        Header: 'Account Holder',
        accessor: 'accountHolder',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'IFSC Code',
        accessor: 'ifscCode',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Branch',
        accessor: 'branchName',
      },
      {
        Header: 'UPI ID',
        accessor: 'upiId',
      },
      {
        Header: 'QR Ref',
        accessor: 'qrReference',
      },
    ];
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Invoice Details
          <img src={DownArrow} alt='download' className='downArrow' />
        </h3>

        <div className='border-bottom'>
          <div className='row'>
            <TextLabel
              label='Invoice Status'
              value={data?.invoiceStatus}
              className='col-md-3'
            />
            <TextLabel
              label='Funding Status'
              value={data?.fundingStatus}
              className='col-md-3'
            />
            <TextLabel
              label='Invoice Date'
              value={data?.invoiceDate}
              className='col-md-3'
            />
            <TextLabel
              label='Party'
              value={data?.partyName}
              className='col-md-3'
            />
            <TextLabel
              label='Payment Date'
              value={data?.paymentDate}
              className='col-md-3'
            />
            <TextLabel
              label='Payment Mode'
              value={data?.paymentMode}
              className='col-md-3'
            />
            <TextLabel
              label='Payment Reference'
              value={data?.paymentReference}
              className='col-md-3'
            />
          </div>
        </div>
        <h3 className='card_heading mt-4 mb-4'>Bank Account Details</h3>
        <ReactTable
          columns={outstandingInvoiceColumns}
          data={paymentDetailsArray}
          defaultPageSize={1}
          showPagination={false}
        />

        <h3 className='card_heading mt-4 mb-4'>
          Uploaded Invoice
          <a onClick={this.invoiceDownload}>
            <img src={DownArrow} alt='download' className='downArrow' />
          </a>
        </h3>
        {invoiceFileUrl && invoiceFileUrl.toLowerCase().includes('pdf') ? (
          <iframe
            title='Invoice'
            src={invoiceFileUrl}
            className='profile_img  preview_img'
          ></iframe>
        ) : (
          <img
            src={invoiceFileUrl}
            alt='invoice_image'
            className='invoice_img'
          />
        )}

        {/* E-invoice */}
        {eInvoices ? (
          <>
            <h3 className='card_heading mt-4 mb-4'>
              <div className='d-flex justify-content-between'>
                <div>e-Invoice Id :{eInvoices?.docId ?? ''}</div>
              </div>
            </h3>
            <img
              alt='e-invoice'
              src={eInvoices?.fileUrl ?? ''}
              className='invoice_img'
            />
          </>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ AllInvoicesReducer }) => ({
  eInvoices: AllInvoicesReducer?.eInvoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getEInvoiceData,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisbursedInvoiceModal);

import React from 'react';
import KycNewModal from './KycNewModal';
import OfflineVerification from './OffilineVerificationModal';
import Digilocker from './DigilockerModal';
import { useState } from 'react';
import ApplicantDoc from './ApplicantDocModal';

function App(props) {
  const [selectedTab, setSelectedTab] = useState(3);

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };
  let { propsData = {} } = props;
  let { digiLocker = {} } = propsData;
  let { promoterData } = props || {};
  let { documentResponse = [] } = promoterData || {};
  return (
    <div>
      <div className='entity-kyc-wrapper'>
        <h3 className='card_heading'>Applicant 1 - {promoterData?.fullName}</h3>
      </div>
      <div className='tab-wrapper mt-5'>
        <div className='tab-container '>
          <div
            className={`tab ${selectedTab === 3 ? 'selected' : ''}`}
            onClick={() => handleTabClick(3)}
          >
            Uploaded Documents
          </div>
          <div
            className={`tab ${selectedTab === 1 ? 'selected' : ''}`}
            onClick={() => handleTabClick(1)}
          >
            Digilocker
          </div>
          <div
            className={`tab ${selectedTab === 2 ? 'selected' : ''}`}
            onClick={() => handleTabClick(2)}
          >
            Offline Verification
          </div>
          <div
            className={`tab ${selectedTab === 0 ? 'selected' : ''}`}
            onClick={() => handleTabClick(0)}
          >
            CKYC
          </div>
        </div>
      </div>
      {selectedTab === 0 && <KycNewModal />}
      {selectedTab === 1 && (
        <Digilocker digiLocker={digiLocker} propsData={propsData} />
      )}
      {selectedTab === 2 && <OfflineVerification propsData={propsData} />}
      {selectedTab === 3 && (
        <ApplicantDoc documentResponse={documentResponse} {...props} />
      )}
    </div>
  );
}

export default App;

import React, { Component } from 'react';
import InvoiceButton from '../../../assets/images/invoice-button.png';
import SearchIcon from '../../../assets/images/SearchIcon.svg';
import ReactTable from 'react-table';
import Excel from '../../../assets/images/ExcelNewIcon.png';
import Button from '../../../components/Button/index';
import CheckBox from '../../../components/CheckBox/CheckBox';
import { getInvoiceList, getInvoiceSummary } from './disbursedInvoices.action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  API_GET_LOAN_INFO,
  API_POST_DOWNLOAD_DISBURSED_INVOICES,
  API_POST_INVOICE_DETAILS,
} from '../../../utils/APIUrls';
import axios from 'axios';
import FileDownload from 'js-file-download';
import DisbursedInvoiceModal from './DisbursedInvoicesModal';
import { GET, POST } from '../../../utils/webAPI.service';
import CredoModal from '../../../components/CredoModal';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { loaderModal } from '../../../store/actions';
import paymentMarginModal from '../PreviewPaymentModal';
import DetailButton from '../../../assets/images/detailicon.png';
import InvoiceInterestDetails from '../../LeadManagement/InterestAndCharges/InvoiceInterestDetails';
import {
  STATUS_PARAMS,
  STATUS_PARAMS_VALUE_MAP,
} from '../ALLInvoices/AllInvoices.config';
import TextLabel from '../../../components/TextLabel';

class DisbursedInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false,
      showFilter: false,
      showDatePicker: false,
      isModalOpen: false,
      modalType: '',
      invoicesTableData: [],
      selectedInvoices: [],
      invoiceModalDetails: {},
      currentPage: 1,
      endDate: null,
      startDate: null,
      duration: null,
      paymentModalData: {},
    };
  }

  getPageData = (type, totalPages) => {
    let { currentPage } = this.state;
    if (type === 'next' && currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    } else if (type === 'prev' && currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value?.trim(),
    });
  };

  searchHandler = async (e) => {
    e.preventDefault();
    let { actions } = this.props;
    let { searchBy, searchValue, duration } = this.state;
    let { getInvoiceList, loaderModal } = actions;
    await this.setState({
      currentPage: 1,
    });
    let dataToSend = {
      duration,
      endDate: null,
      numberOfRows: 10,
      pageNumber: 1,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: null,
      type: 'disbursed',
    };
    if (searchBy === undefined) {
      alert('Please select search type');
      return;
    }
    loaderModal(true);
    await getInvoiceList(dataToSend);
    loaderModal(false);
    this.setState({
      invoicesTableData: this.props?.disbursedInvoicesListData?.invoiceDetails,
    });
  };

  getFilterData = async (duration) => {
    await this.setState((prevState) => ({
      ...prevState,
      showFilter: !prevState.showFilter,
      duration,
      currentPage: 1,
    }));
    this.invoiceData();
  };

  getDateFilterData = async () => {
    let { actions } = this.props;
    let { endDate, startDate } = this.state;
    let { getInvoiceList, getInvoiceSummary } = actions;
    let dataToSend = {
      endDate: endDate?.split('-')?.reverse()?.join('-'),
      numberOfRows: 10,
      pageNumber: 1,
      searchParam: null,
      searchValue: null,
      startDate: startDate?.split('-')?.reverse()?.join('-'),
      type: 'disbursed',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    await getInvoiceSummary(dataToSend);
    loaderModal(false);
    this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
      showFilter: !prevState.showFilter,
      currentPage: 1,
      invoicesTableData: this.props?.disbursedInvoicesListData?.invoiceDetails,
    }));
  };

  toggleModal = (
    modalType,
    invoiceId,
    userId,
    invoiceAmount,
    fundedAmount,
    subventionAmount,
    marginAmount
  ) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      paymentModalData: {
        invoiceId,
        invoiceAmount,
        fundedAmount,
        subventionAmount,
        marginAmount,
      },
    });
    let dataToSend = {
      invoiceId,
      userId,
    };
  };

  toggleDisburedModal = (
    modalType,
    invoiceId,
    userId,
    invoiceAmount,
    fundedAmount,
    subventionAmount,
    marginAmount
  ) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      paymentModalData: {
        invoiceId,
        invoiceAmount,
        fundedAmount,
        subventionAmount,
        marginAmount,
      },
    });
    let dataToSend = {
      invoiceId,
      userId,
    };

    POST(API_POST_INVOICE_DETAILS, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          this.setState({
            invoiceModalDetails: data,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  invoiceData = async () => {
    let { actions } = this.props;
    let { duration, currentPage, searchValue, searchBy } = this.state;
    let { getInvoiceList, getInvoiceSummary, loaderModal } = actions;
    let dataToSend = {
      duration: duration || 'till_now',
      endDate: null,
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: null,
      type: 'disbursed',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    await getInvoiceSummary(dataToSend);
    this.setState({
      invoicesTableData: this.props?.disbursedInvoicesListData?.invoiceDetails,
    });
    loaderModal(false);
  };

  getPageChangeInvoiceData = async () => {
    let { actions } = this.props;
    let { duration, currentPage, searchValue, searchBy, endDate, startDate } =
      this.state;
    let { getInvoiceList, loaderModal } = actions;
    let dataToSend = {
      duration,
      endDate: endDate?.split('-')?.reverse()?.join('-'),
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: startDate?.split('-')?.reverse()?.join('-'),
      type: 'disbursed',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    loaderModal(false);
    this.setState({
      invoicesTableData: this.props?.disbursedInvoicesListData?.invoiceDetails,
    });
  };

  componentDidMount = async () => {
    await this.invoiceData();
  };

  handleChange = (e) => {
    const { invoicesTableData } = this.state;
    let invoicesTableDataCopy = [...invoicesTableData];
    this.setState({
      invoicesTableData: invoicesTableDataCopy?.map((invoicesDataCopy) => ({
        ...invoicesDataCopy,
        isChecked: e.target.checked,
      })),
    });
  };

  downloadExcel = () => {
    const { invoicesTableData } = this.state;
    let checkedInvoices = invoicesTableData
      ?.filter((item) => item?.isChecked === true)
      .map((e) => e?.invoiceId);
    if (checkedInvoices?.length === 0) {
      alert('Please select the invoice to download excel');
      return;
    }
    const dataToSend = {
        invoiceIds: checkedInvoices,
      },
      token = localStorage.getItem('token');
    const headers = {
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': localStorage.getItem('userId'),
      Authorization: `Bearer ${token}`,
    };
    axios({
      url: `${API_POST_DOWNLOAD_DISBURSED_INVOICES}`,
      method: 'POST',
      headers: headers,
      responseType: 'blob',
      data: dataToSend,
    }).then((res) => {
      FileDownload(res.data, `disbursed_invoices.xlsx`);
    });
  };

  getLoanInfo = (userId) => {
    loaderModal(true);
    GET(API_GET_LOAN_INFO(userId))
      .then(({ data }) => {
        if (data?.code === 200) {
          if (data?.data) {
            setTimeout(function () {
              let newWindow = window.open(
                `/pay-app/${data?.data?.loanId}/${userId}/application/overview`
              );
              newWindow.focus();
            }, 350);
            loaderModal(false);
          }
        } else {
          alert(data?.message);
          loaderModal(false);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
    loaderModal(false);
  };

  render() {
    let { disbursedInvoicesListData, disbursedInvoicesSummary } = this.props;
    let { summary } = disbursedInvoicesSummary || {};
    let { disbursed } = summary || {};
    let {
      showFilter,
      showDatePicker,
      invoicesTableData,
      modalType,
      invoiceModalDetails,
      currentPage,
      paymentModalData,
    } = this.state;
    let { numberOfPages } = disbursedInvoicesListData || {};
    const disbursedInvoicesColumn = [
      {
        Header: () => {
          let isAllSelected = invoicesTableData?.some(
            (invoicesData) => invoicesData?.isChecked !== true
          );
          return (
            <CheckBox
              checkboxColor={'#ADADAD'}
              htmlFor={'filter1'}
              inputClass={''}
              labelClass={''}
              checked={!isAllSelected}
              onChange={(e) => this.handleChange(e)}
            />
          );
        },
        width: 60,
        accessor: 'isChecked',
        Cell: ({ value, index }) => {
          const onCheckChange = () => {
            let invoicesTableDataCopy = [...this.state.invoicesTableData];
            invoicesTableDataCopy[index] = {
              ...invoicesTableDataCopy[index],
              isChecked: !value,
            };
            this.setState({ invoicesTableData: invoicesTableDataCopy });
          };

          return (
            <div>
              <CheckBox
                checkboxColor={'#ADADAD'}
                htmlFor={'filter1'}
                inputClass={''}
                labelClass={''}
                checked={value}
                onChange={onCheckChange}
              />
            </div>
          );
        },
      },
      {
        Header: 'User Id',
        accessor: 'userId',
        width: 120,
        Cell: (props) => {
          let { original } = props;
          let { userId } = original;
          return (
            <>
              {
                <div
                  className='invoice-pointer'
                  onClick={() => this.getLoanInfo(userId)}
                >
                  {userId || 'NA'}
                </div>
              }
            </>
          );
        },
      },

      {
        Header: 'Invoice Id',
        width: 170,
        accessor: 'invoiceId',
        Cell: ({ original }) => {
          let { disbursalStatus } = original || {};
          return (
            <>
              <div
                className='right-aligned invoice-pointer  pr-5'
                onClick={() =>
                  this.toggleModal(InvoiceInterestDetails, original?.invoiceId)
                }
              >
                {original?.invoiceId}
              </div>

              {disbursalStatus === STATUS_PARAMS.IN_REVIEW ? (
                <span className='capsule ml-4 capsule_orange'>
                  {STATUS_PARAMS_VALUE_MAP.IN_REVIEW}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.PROCESSING ? (
                <span className='capsule  ml-4  capsule_orange'>
                  {STATUS_PARAMS_VALUE_MAP.PROCESSING}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.PROCESSED ? (
                <span className='capsule ml-4 capsule_new_green'>
                  {STATUS_PARAMS_VALUE_MAP.PROCESSED}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.ON_HOLD ? (
                <span className='capsule ml-4 capsule_grey'>
                  {STATUS_PARAMS_VALUE_MAP.ON_HOLD}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.FAILED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.FAILED}{' '}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.REJECTED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.REJECTED}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.BANK_FAILED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.BANK_FAILED}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.DRAFT ? (
                <span className='capsule ml-4 capsule_blue'>
                  {STATUS_PARAMS_VALUE_MAP.DRAFT}
                </span>
              ) : (
                <span className='capsule ml-4 capsule_blue'>
                  {disbursalStatus}
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
        width: 160,
      },
      {
        Header: () => (
          <>
            <div>Transaction</div>
            <div>Date</div>
          </>
        ),
        accessor: 'transactionDate',
        width: 100,
        Cell: (props) => {
          let { original } = props;
          let { transactionDate } = original;
          return (
            <>
              <div>{transactionDate || 'NA'}</div>
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            <div className='ml-4'>Invoice</div>
            <div className='ml-4'>Amount</div>
          </>
        ),
        accessor: 'invoiceAmount',
        width: 160,
        Cell: (props) => {
          let { original } = props;
          let { invoiceAmount } = original;

          return (
            <>
              <div className='ml-4'>
                {rupeeDecimalFormatter(invoiceAmount, '₹')}
              </div>
            </>
          );
        },
      },
      {
        Header: 'UTR',
        accessor: 'utr',
        width: 130,
      },
      {
        Header: () => (
          <>
            <div>Payment</div>
            <div>Amount</div>
          </>
        ),
        accessor: 'fundedAmount',
        width: 180,
        Cell: (props) => {
          let { original } = props;
          let {
            fundedAmount,
            invoiceId,
            userId,
            invoiceAmount,
            subventionAmount,
            marginAmount,
          } = original;

          return (
            <div className='invoices-wrapper d-flex justify-content-between'>
              {rupeeDecimalFormatter(fundedAmount, '₹')}
              <span>
                <img
                  className='preview-margin-modal'
                  alt='icon'
                  src={DetailButton}
                  onClick={() =>
                    this.toggleModal(
                      paymentMarginModal,
                      invoiceId,
                      userId,
                      invoiceAmount,
                      fundedAmount,
                      subventionAmount,
                      marginAmount
                    )
                  }
                />
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: (props) => {
          let { original } = props;
          let { invoiceId, userId } = original;

          return (
            <button
              className='details_button'
              onClick={() =>
                this.toggleDisburedModal(
                  DisbursedInvoiceModal,
                  invoiceId,
                  userId
                )
              }
            >
              Details
            </button>
          );
        },
      },
    ];
    return (
      <>
        <div className='container invoices-wrapper'>
          <div className='card'>
            <div className='invoice-header'>
              <h3 className='card_heading'>INVOICE SUMMARY</h3>
              <img
                onClick={() =>
                  this.setState((prevState) => ({
                    ...prevState,
                    showFilter: !prevState.showFilter,
                  }))
                }
                alt='filter'
                className='invoice-btn mt-5'
                src={InvoiceButton}
              />
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <div className='row'>
                  <TextLabel
                    label='Total Disbursed Invoice'
                    value={disbursed?.count}
                    className='col-md-12'
                  />
                  <TextLabel
                    label='Total Amount'
                    value={rupeeDecimalFormatter(disbursed?.amount, '₹')}
                    className='col-md-12'
                  />
                </div>
              </div>
              <div className='col-md-4'>
                {showFilter ? (
                  <div className='card card-filter'>
                    <ul className='list-group list-group-flush'>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('today')}
                      >
                        24 hours
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('one_week')}
                      >
                        Past one week
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('one_month')}
                      >
                        Past one month
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() =>
                          this.setState((prevState) => ({
                            ...prevState,
                            showDatePicker: !prevState.showDatePicker,
                          }))
                        }
                      >
                        Choose date
                      </li>
                    </ul>
                  </div>
                ) : null}
                {showDatePicker && showFilter ? (
                  <div className='card date-picker'>
                    <div className='row'>
                      <div className='col mt-5'>
                        Start Date
                        <input
                          className='date'
                          type='date'
                          onChange={(e) =>
                            this.setState({
                              startDate: e.target.value,
                            })
                          }
                        ></input>
                      </div>
                      <div className='col mt-5'>
                        End Date
                        <input
                          className='date'
                          type='date'
                          onChange={(e) =>
                            this.setState({
                              endDate: e.target.value,
                            })
                          }
                        ></input>
                      </div>
                    </div>
                    <div className='row mt-3 ml-2'>
                      <Button onClick={() => this.getDateFilterData()}>
                        OK
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='row'>
              <div className='col-5 mt-5'>
                <form onSubmit={(e) => this.searchHandler(e)}>
                  <div className='search-form'>
                    <select
                      className='select '
                      onChange={this.handleInputChange}
                      name='searchBy'
                      required
                    >
                      <option disabled selected>
                        Search By
                      </option>
                      <option value={'userId'}>User Id</option>
                      <option value={'mobileNumber'}>
                        Registered Mobile Number
                      </option>
                      <option value={'invoiceNumber'}>Invoice No</option>
                      <option value={'uniqueInvoiceId'}>Invoice Id</option>
                    </select>
                    <input
                      className='select input'
                      required={true}
                      placeholder='Search'
                      name='searchValue'
                      onChange={this.handleInputChange}
                    />
                    <button type='submit' className='submit'>
                      <img src={SearchIcon} alt='icon' />
                    </button>
                  </div>
                </form>
              </div>
              <div className='col mt-3'>
                <div className='row d-flex justify-content-end'>
                  <h5
                    className='col-3 btn-text button add_button button_invoices btn-green '
                    onClick={this.downloadExcel}
                  >
                    <img src={Excel} alt='icon' />
                    <span>Download Excel</span>
                  </h5>
                </div>
              </div>
            </div>
            <h3 className='card_heading_secondary'>Disbursed Invoice</h3>
            <ReactTable
              data={invoicesTableData}
              columns={disbursedInvoicesColumn}
              defaultPageSize={10}
              showPagination={false}
              sortable={false}
            />
            <ul className={'datatable-pagination'}>
              <li>
                <button
                  disabled={!(currentPage > 1)}
                  className={'prev-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('prev', numberOfPages)}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {numberOfPages === 0 ? 0 : currentPage} of{' '}
                  {numberOfPages}
                </span>
              </li>
              <li>
                <button
                  disabled={!(numberOfPages > currentPage)}
                  className={'next-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('next', numberOfPages)}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
          <CredoModal
            isOpen={this.state.isModalOpen}
            styles={
              modalType === paymentMarginModal
                ? {
                    content: {
                      width: '60rem',
                      height: '40rem',
                    },
                  }
                : modalType === InvoiceInterestDetails
                ? {
                    content: {
                      width: '90vw',
                      height: '90vh',
                    },
                  }
                : {
                    content: {
                      minWidth: '60vw',
                      height: 'auto',
                    },
                  }
            }
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            invoiceModalDetails={invoiceModalDetails}
            paymentModalData={paymentModalData}
            toggleModal={this.toggleModal}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ DisbursedInvoicesReducer }) => ({
  disbursedInvoicesListData:
    DisbursedInvoicesReducer?.disbursedInvoicesListData,
  disbursedInvoicesSummary: DisbursedInvoicesReducer?.disbursedInvoicesSummary,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvoiceList,
      getInvoiceSummary,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisbursedInvoices);

import React, { Component } from 'react';
import ReactTable from 'react-table';
import DownArrow from '../../../assets/images/downArrow.png';
import TextLabel from '../../../components/TextLabel';

export default class MarginModal extends Component {
  render() {
    let { marginDetails } = this.props;
    let { data } = marginDetails || {};
    let { paymentDetails } = data || [];
    const paymentDetailsArray = paymentDetails ? [paymentDetails] : [];

    const outstandingInvoiceColumns = [
      {
        Header: 'Account Holder',
        accessor: 'accountHolder',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'IFSC Code',
        accessor: 'ifscCode',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Branch',
        accessor: 'branchName',
      },
      {
        Header: 'UPI ID',
        accessor: 'upiId',
      },
      {
        Header: 'QR Ref',
        accessor: 'qrReference',
      },
    ];
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Invoice Details
          <img src={DownArrow} alt='download' className='downArrow' />
        </h3>

        <div className='border-bottom'>
          <div className='row'>
            <TextLabel
              label='Invoice Status'
              value={data?.invoiceStatus}
              className='col-md-3'
            />
            <TextLabel
              label='Funding Status'
              value={data?.fundingStatus}
              className='col-md-3'
            />
            <TextLabel
              label='Invoice Date'
              value={data?.invoiceDate}
              className='col-md-3'
            />
            <TextLabel
              label='Party'
              value={data?.partyName}
              className='col-md-3'
            />
            <TextLabel
              label='Payment Date'
              value={data?.paymentDate}
              className='col-md-3'
            />
            <TextLabel
              label='Payment Mode'
              value={data?.paymentMode}
              className='col-md-3'
            />
            <TextLabel
              label='Payment Reference'
              value={data?.paymentReference}
              className='col-md-3'
            />
          </div>
        </div>
        <h3 className='card_heading mt-4 mb-4'>Bank Account Details</h3>
        <ReactTable
          columns={outstandingInvoiceColumns}
          data={paymentDetailsArray}
          defaultPageSize={1}
          showPagination={false}
        />
      </div>
    );
  }
}
